.progress-bar {
  transition: width 0.7s;
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999;
}

.progress-bar.loading {
  width: 33%;
  height: 3px;
  background-color: #4799eb;
  transition: width 0.5s ease;
}

.progress-bar.loaded {
  transition: width 0.7s, height 0.1s linear 1s;
  width: 100%;
  height: 3px;
  background-color: #4799eb;
}
