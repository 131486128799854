.loader {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 99999999;
}

.loader.loading {
  border: 2px solid #00000000; /* Blue */
  border-top-color: #4799eb; /* Blue */
  border-left-color: #4799eb;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.4s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
