.container {
  margin-top: 30px;
  margin-bottom: 30px
}

.container :global(.ui.table) {
  background-color: #1f364d;
}

.container :global(.ui.table) tr:hover, .container :global(.ui.table) tr.active {
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.pageInput:global(.ui.input) > input {
  max-width: 80px;
  color: white !important;
}

.previous:global(.ui.primary.button) {
  margin-right: 0;
}

.limit:global(.ui.input) > input {
  max-width: 80px;
  margin-right: 20px;
  color: white !important;
}

.limit:global(.ui.input) > :global(.ui.label) {
  margin-left: 0;
  background-color: #4799eb;
  color: #fff !important;
}

.filterDeliveredScouter:global(.ui.checkbox.toggle) {
  /* display: block; */
  margin: 20px 20px 20px 0;
}

.filterDeliveredScouter:global(.ui.checkbox.toggle) > label,
.filterDeliveredScouter:global(.ui.checkbox.toggle) > input:checked ~ label {
  color: #fff !important;
}