.ui.container.analytics-container {
  position: relative;
  width: auto !important;
  max-width: none !important;
  margin: 0 1em !important;
  padding: 0;
  height: calc(100vh - 66px);
  overflow: auto;
}

.analytics-table.ui.inverted.striped.table .table tbody tr:nth-child(2n) {
    background-color: #00000000;
}

.analytics-table.ui.inverted.striped.table tbody tr:nth-child(2n) .table {
    background-color: #00000000;
}