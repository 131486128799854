* {
  /* font-family: 'Source Sans Pro Black', Source Sans Pro, sans-serif !important; */
  font-family: 'Source Sans Pro', Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
}
.settings .forgotten-password {
  margin: -12px 0 20px;
}

.settings .verify-button {
  margin-top: -12px;
}

.cancel-button {
  box-shadow: inset 0 0 0 2px #274562 !important;
}

.cancel-button:hover {
  box-shadow: none !important;
}

.forgotten-password-btn-wrapper {
  height: 34px;
}

.select-organizer label {
  font-size: 13px !important;
  color: white !important;
  margin-bottom: 10px !important;
}

.entity-label {
  border: 1px solid rgba(15,36,57,.8705882352941177) !important; ;
  border-radius: 3px ;
  background-color: initial ;
  position: relative ;
  color: #fff ;
  padding: 8px 18px ;
  margin: 5px 10px 5px 0 ;
  font-weight: 700 ;
}

.label {
  font-size: 13px !important;
}

.settings .forgotten-password .forgotten-password-btn {
  font-family: 'Source Sans Pro';
  border: 2px solid transparent;
  color: #4799eb;
  height: 34px;
  font-weight: 600;
  cursor: pointer;
}
.PhoneInputCountry {
  color: white !important;
  left: 10px !important;
}

.settings .forgotten-password .forgotten-password-btn:hover {
  color: #5ea6ee;
}

.settings .forgotten-password h5.reset-button {
  color: #9cb3c9;
  height: 34px;
}

.settings .forgotten-password .loader {
  margin: 0 0 9.875px !important;
}

.date-time {
  display: flex;
  align-items: baseline;
}

.date-time .field {
  width: 100%;
}

.form-upload {
  display: flex;
  align-items: center;
}

.form-upload a {
  color: #4799eb;
  font-size: 14px;
  font-weight: 600;
}

.form-upload a:hover {
  color: #5ea6ee;
  cursor: pointer;
}

.form-upload input {
  display: none;
}

.end-date-check label {
  display: block !important;
  margin: 0 0 0.28571429rem 0 !important;
  color: #fff !important;
  font-size: 0.92857143em !important;
  font-weight: 600 !important;
  text-transform: none !important;
}

.form-section .select-organizer .menu {
  width: 100% !important;
  margin-top: 0.5em !important;
}
.form-section .select-organizer .ui.dropdown.selection {
  border: 1px solid rgba(15, 36, 57, 0.8705882352941177);
}
.cancel_box label{
  font-size: 13px !important;
  color: white !important;
  margin-bottom: 10px !important;
  font-weight: 600 !important;
} 

@media only screen and (min-width: 320px), only screen and (max-width: 768px) {
  .form-template-wrapper {
    margin: 30px auto;
    /* padding:10px; */
    max-width: 342px;
  }
}
@media (min-width: 320px) {
  .form-template-wrapper {
    /* margin: 30px auto; */
    /* padding: 10px; */
    max-width: 542px;
  }
}

@media (min-width: 1200px) {
  .form-template-wrapper {
    margin: 30px auto;
    /* padding:10px; */
    max-width: 342px;
  }
}
