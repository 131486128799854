.container {
  height: calc(100vh - 56px);
  overflow: scroll;
}

.container h1 {
  margin: 10px 30px;
}

.cardWrapper {
  display: table-row;
}

.card {
  margin: 0 30px;
  display: flex;
  flex-direction: row !important;
  align-items: flex-end;
  background: #1f364d;
  padding: 24px;  
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 8px #0c1f31;
  flex-wrap: wrap;
}

.dropdownWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  margin: 0 15px 15px 0;
}