.ui.progress.onboarding-progress-bar {
  margin: 0;
}

.ui.progress.onboarding-progress-bar .bar {
  background-color: #2185d0;
  height: 7px;
  border-radius: 0;
}

.onboarding-wrapper,
.onboarding-5-container,
.onboarding-4-container {
  margin-top: 50px;
}

.onboarding-heading {
  font-family: "Source Sans Pro", sans-serif !important;
  line-height: 1.3;
  color: white;
  font-size: 40px;
  font-weight: 400;
}
.PhoneInput {
  border: 1px solid #0f2439 !important;
}

.ui.form .onboarding-form-wrapper .field > label {
  font-family: "Source Sans Pro", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 13px !important;
  font-weight: 400;
}

input.PhoneInputInput {
  border: 0px solid #0f2439 !important;
}

.onboarding-sub-heading {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 18px;
  margin: 25px 10px 28px 0;
  line-height: 25px;
  font-weight: 400;
  color: #9cb3c9;
}

.onboarding-sub-heading p{
  color: #9cb3c9;
  line-height: 1.4em;
  font-weight: 400;
  max-width: 520px;
}

.facebook .kep-login-facebook.metro {
  margin-top: 2vw;
  padding: 8px 20px;
}

.onboarding-5-container .profile-form,
.onboarding-form-wrapper.form {
  width: 494px;
}

.onboarding-5-container .onboarding-form-wrapper {
  max-width: 329px;
}

.onboarding-5-container .onboarding-form-wrapper .margin {
  margin: 14px 0;
}

.onboarding-5-container .onboarding-form-wrapper .margin .input {
  width: 100%;
}

.onboarding-5-container .onboarding-form-wrapper .margin .input > input:active,
.onboarding-5-container .onboarding-form-wrapper .margin .input > input:focus {
  color: #000;
}

.onboarding-5-container.organizers .ui.form .field:last-of-type {
  /* margin-bottom: -20px; */
}

.onboarding-5-container .ui.form .field > label {
  font-weight: 400;
  margin-bottom: 8px;
} 

.onboarding-4-container .form-wrapper {
  max-width: 596px;
}

.onboarding-4-container .view-change {
  margin-top: -10px;
  margin-bottom: 20px;
}

.onboarding-4-container .view-change .radio label::before,
.onboarding-4-container .view-change .radio input:checked ~ label:before,
.onboarding-4-container .view-change .radio input:focus ~ label:before,
.onboarding-4-container .view-change .radio input:hover ~ label:before {
  border-color: #1f364d;
  border-width: 2px;
}

.onboarding-4-container .view-change .radio:first-child {
  margin-right: 10px;
}

.onboarding-5-container .swa.ui.search .ui.input .prompt,
.onboarding-4-container .swa.ui.search .ui.input .prompt {
  width: 100%;
}

.onboarding-4-container .school-years,
.onboarding-4-container .work-years {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.onboarding-4-container .school-years .field .dropdown,
.onboarding-4-container .work-years .field .dropdown {
  min-width: 0;
}

.onboarding-4-container .pointer {
  margin-bottom: 10px;
}

.onboarding-4-container .work-years .field .dropdown:nth-child(2) {
  margin-bottom: 10px;
}

.onboarding-4-container .work-years .present {
  margin: auto 0;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.onboarding-5-container .skill-form .swa {
  margin-bottom: 10px;
}

.onboarding-5-container .skill-form .entities-wrapper:empty {
  margin-top: 0;
  margin-bottom: -10px;
}

.onboarding-5-container .ui.form .field.facebook {
  margin-bottom: 20px;
}

.onboarding-5-container .entities-wrapper .entity-label.ui.label.basic {
  border-color: #0e2439;
}

.onboarding-5-container
  .entities-wrapper
  .entity-label.ui.label.basic
  .remove
  span {
  background-color: #0e2439;
}

.onboarding-6-container .column {
  margin-top: 50px;
}

.onboarding-6-container h1 {
  font-size: 34px;
  font-weight: 700;
}

.onboarding-6-container .sub-heading {
  font-size: 16px;
  line-height: 1.8;
}

.onboarding-6-container .image {
  object-fit: cover;
  margin: 20px auto;
}

.onboarding-4-container,
.onboarding-5-container,
.onboarding-6-container {
  margin-bottom: 30px;
}

.onboarding-column .facebook .kep-login-facebook.metro {
  margin-top: 0;
  text-transform: none;
  background-color: #3c5a99;
  border-color: #3c5a99;
}

.onboarding-column .ui.form .field {
  margin-bottom: 28px;
}

.share{
  display: flex;
  flex-direction: column;
}

.share-btn {
  margin: 5px 5px;
}

.insta-share{
  color: white;
}
.insta-share:hover{
  text-decoration: underline;
}

.react-share__ShareButton:hover{
  color: #2185d0 !important;
}
.insta-share:hover{
  color: white;
}

.onboarding-toggle {
  margin-bottom: 20px;
}

.onboarding-toggle .ui.checkbox.toggle label:before,
.onboarding-toggle .ui.checkbox.toggle label:after {
  top: 50%;
  transform: translateY(-50%);
}

.onboarding-toggle .ui.checkbox label,
.onboarding-toggle .ui.checkbox.checked label,
.onboarding-toggle .ui.checkbox input:focus:checked~label,
.onboarding-toggle .ui.toggle.checkbox.checked label,
.onboarding-toggle .ui.toggle.checkbox input:focus:checked~label {
  display: block !important;
  color: #9cb3c9 !important;
  font-size: 18px !important; 
  line-height: 22px;
  /* font-weight: 600 !important; */
  text-transform: none !important;
}

.onboarding-5-container.organizers .ui.card {
  background-color: #1f364d;
  max-width: 494px;
}



.onboarding-5-container.organizers .form .ui.card .field input {
  border-color: #0f2439 !important;
}

.onboarding-5-container.organizers .form .ui.card .field input:focus {
  border-color: #85b7d9 !important;
}

.invites .onboarding-form-wrapper {
  max-width: 400px;
}


.invites .onboarding-5-container .onboarding-form-field {
  margin-bottom: 20px;
}

.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper {
  display: block !important;
}

.invites .onboarding-5-container .field.onboarding-form-field:last-child {
  margin-bottom: 0;
}

.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper input {
  /* margin: 0 4px !important; */
  display: inline;
    width: calc(100% - 10px);
    margin-bottom: 10px !important;
}

.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper .remove-btn {
  /* height: 20px !important;
  width: 20px !important;
  display: inline-flex !important;
  align-items: center;*/
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;

  vertical-align: text-bottom;
  line-height: 18px;
} 


.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper .remove-btn.hidden {
  opacity: 0 !important;
  cursor: unset;
}

.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper span {
  display: inline-flex;
  align-items: center;
  color: #fff;
  font-size: 0.92857143em;
}

.invites .onboarding-5-container .onboarding-form-field-inputs-wrapper span:first-of-type {
  height: 30px;
  margin-left: 6px;
}


.onboarding-5-container.organizers .entities-wrapper .entity-label.ui.label.basic {
  border-color: #0f2439 !important;
}

.onboarding-5-container.organizers .ui.dropdown .menu {
  max-height: 16.02857142rem;
  background-color: #0e2439;
  border: 1px solid #1f364d !important;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
  margin-top: 3px !important;
}

.onboarding-5-container.organizers .ui.selection.dropdown .menu::-webkit-scrollbar {
  width: 0.4em;
}

/* Handle */
.onboarding-5-container.organizers .ui.selection.dropdown .menu::-webkit-scrollbar-thumb {
  background: #9cb3c9;
}

.onboarding-5-container.organizers .ui.selection.dropdown .menu .item[data-id="default"] {
  display: none;
}

.onboarding-5-container.organizers .entities-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;

}

.onboarding-wrapper.thank-you p {
  margin-bottom: 21px;
  line-height: 22px;
}

.onboarding-wrapper.thank-you .onboarding-toggle {
  margin-bottom: 21px
}
/* 
.onboarding-wrapper.thank-you .onboarding-sub-heading {
  margin-top: 9px
} */

.onboarding-wrapper.thank-you .ui.primary.button {
  margin-top: 3px;
  margin-bottom: 20px;
}


.player-limiter {
  max-width: 500px;
  margin-bottom: 35px
}

.player-wrapper {
  position: relative;
  width: 100%;
  /* height: 0; */
  padding-bottom: 56.25%;
  overflow: hidden;
}

.player-wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} 

@media (max-width: 767px) {
  .onboarding-heading {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 30px;
  }

  .onboarding-sub-heading {
    font-family: "Source Sans Pro", sans-serif !important;
    font-size: 16px !important;
  }

  .onboarding-toggle .ui.checkbox label,
  .onboarding-toggle .ui.checkbox.checked label,
  .onboarding-toggle .ui.checkbox input:focus:checked~label,
  .onboarding-toggle .ui.toggle.checkbox.checked label,
  .onboarding-toggle .ui.toggle.checkbox input:focus:checked~label {
    font-size: 16px !important; 
  }

  .onboarding-form-wrapper.ui.form {
    width: 100%;
    max-width: 405px;
  }
}

.onboarding-5-container.install {
  margin-bottom: 20px;
}

.onboarding-5-container.install .ui.card {
  background-color: #1f364d;
  max-width: 329px;
}

.onboarding-5-container.install .ui.form .field .ui.search.dropdown {
  max-width: 100% !important;
  width: 100% !important;
}


.onboarding-5-container.install .install-instructions {
  margin-top: 6px;
  max-height: 325px;
}


.onboarding-5-container.install .instructions-title {

}

.onboarding-5-container.install .instructions {
  padding-left: 0;
  /* list-style: none; */
  margin-top: 6px;
  list-style-position: inside;
  list-style: none;
}

.onboarding-5-container.install .instructions li {

}

.onboarding-5-container.install .install-button {
  margin-top: 5px;
}
