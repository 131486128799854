.links-container {
    margin-top: 30px;
}

.links-container .ui.header {
    font-size: 18px;
    line-height: 32px;
    color: white;
    margin: 0 0 16px;
    font-weight: 600;
}

.links-container .links {
    padding-bottom: 38px;
}

.links-container .links section {
    width: 100%;
    margin-bottom: 20px;
    border: 3px solid #1f364d !important;
}

.links-container .links .title {
    /* font-family: 'Source Sans Pro Black', Source Sans Pro, sans-serif !important; */
    color: #b3cce6;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    border: 3px solid #0f2439;
    border-radius: 2px;
    font-size: 16px;
    height: 46px;
    padding: 0 14px;
}

.links-container .links .title * {
    margin: auto 0;
}

/* .links-container .links .title:hover {
color: #FFF;
border-color: #385c80;

} */

.links-container .links svg {
    margin-left: auto;
    height: 18px;
    /* width: 18px; */
    fill: #314f6d;
    grid-column: 3;
}

.links-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;    
}

.links-container .links .site-info-row {
    padding: 6px 8px 6px 0;
    display: inline-flex;
    /* margin:10px 10px; */
}

.links-container .links .site-info {
    display: inline-flex;
    align-items: center;
}

.links-container .links .site-info i,
.links-container .links .site-info svg {
    /* width: 16px; */
    height: 16px;
    color: #9cb3c9;
    fill: #9cb3c9;
    margin-right: 10px;
    font-size: 16px;
}

.links-container .links .site-info:hover i,
.links-container .links .site-info:hover svg {
    color: #4799eb;
    fill: #4799eb;
}

.links-container .links .site-info span {
    /* font-family: 'Source Sans Pro Black', Source Sans Pro, sans-serif !important; */
    color: #9cb3c9;
    font-size: 16px;
}

.links-container .links .site-info:hover span {
    color: #fff;
}

.links-container .links .site-info-row .divider {
    margin: 0 6px;
    transform: translateY(1px);
}

.links-container .links .end-links-page {
    display: flex;
    justify-content: space-between;
    padding-right: 18px;
    /* margin-bottom: */
}

.links-container .links .end-links-page a {
    position: relative;
    color: #4799eb;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    transition: color 160ms;
    align-self: flex-start;
    margin-top: 10px;
    margin-right: 13px;
}

.links-container .links .end-links-page a:hover {
    color: #fff;
}

.links-container .links .end-links-page svg {
    height: 14px;
    width: 14px;
    fill: #4799eb;
    left: calc(100% + 6px);
    position: absolute;
    top: calc(50% - 6px);
    -webkit-transition: all 160ms;
    transition: all 160ms;
}

.links-container .links .end-links-page a:hover svg {
    animation: joinButtonArrowSvgGyration 0.9s infinite;
    fill: #fff;
}

.links-container .links .meetup {
    display: flex;
    margin-top: 16px;
}

.links-container .links .meetup .date {
    align-items: center;
    border: 3px solid #0f2439;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    height: 46px;
    justify-content: center;
    margin-top: 4px;
    text-align: center;
    width: 44px;
}

.links-container .links .meetup:hover .date {
    border-color: #385c80;
}

.links-container .links .meetup .date .month {
    color: #b3cce6;
    font-size: 11px;
    font-weight: 600;
    line-height: 1em;
    text-transform: uppercase;
}

.links-container .links .meetup .date .day {
    color: #4799eb;
    font-size: 15px;
    font-weight: 600;
    line-height: 1em;
    margin: 1px auto 0;
}

.links-container .links .meetup .text {
    align-self: center;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    width: calc(100% - 44px - 10px);
}

.links-container .links .meetup .text .name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    color: #fff;
    font-size: 16px;
    line-height: 1.25em;
}

.links-container .links .meetup:hover .text .name {
    background-color: #1f364d;
}

.links-container .links .meetup .text .location {
    color: #9cb3c9;
    font-size: 14px;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.links-container .links .meetup-chat {
    margin-top: 14px;
}

.links-container .links .meetup-chat .country {
    font-size: 16px;
}

.links-container .links .meetup-chat .city {
    margin-top: 2px;
    font-size: 14px;
    color: #9cb3c9;
}

.links-container .links .meetup-chat .city a {
    color: #9cb3c9;
    white-space: nowrap;
    line-height: 22px;
}

.links-container .links .meetup-chat .city a:hover {
    color: white;
    text-decoration: underline;
}

.links-container .links .media {
    display: flex;
}

.links-container .links .icon-links-page a {
    color: #9cb3c9;
    margin-right: 10px;
    font-size: 15px;
}

.links-container .links .icon-links-page a:hover {
    color: #1e70bf;
}