.container {
  top: 0;
  position: absolute;
  /* width: 50%; */
  right: 50%;
  transform: translateX(50%);
  text-align: center;
  z-index: 5;
  max-width: 100%;
  overflow: visible;
}

.banner { 
  border: 1px solid #1f364d;
  border-top: none;
  background-color: #0e2439;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  /* bottom: 0; */
  width: auto;
  white-space: nowrap;
  padding: 10px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.text {
  text-align: center;
  color: #fff;
  display: inline;
  white-space: nowrap;
  max-width: 100vw;
  margin: 5px 20px;
}

.actions {
  display: inline;
  margin: 0 20px 0 0px;
  white-space: nowrap;
}

.button {
  /* background: none; */
  color: #fff;
  border: none;
  margin-right: 10px !important;
  padding: 0;
  display: inline;
  font-size: 14px;
  /* font-weight: 600; */
  text-decoration: underline;
  font-family: 'Source Sans Pro', Lato, 'Helvetica Neue', Arial, Helvetica,
  sans-serif;
}

.button:global(.ui.basic.button) {
  border-color: #1f364d !important;
}

.button:global(.ui.button) {
  padding: 5px 10px;
  font-weight: unset;
}



.button:last-child {
  margin-right: 0;
} 

.icon:global(.bell.icon) {
  margin-right: 0;
  height: 10px !important;
  width: 20px !important;
  font-size: 12px;
}

@media (max-width: 650px) {
  .icon:global(.bell.icon) {
    display: none !important;
  }
}


@media (max-width: 540px) {
  .banner {
    white-space: normal;
    line-height: 31px;
  }

  .text {
    display: block;
    margin: 0 10px;
  }


  .actions {
    margin: 3px 20px 0;
  }
}

@media (max-width: 348px) {
  .banner {
    padding-left: 0;
    padding-right: 0;
  }
}


@media (max-width: 319px) {
  .container {
    display: none;
  }
}