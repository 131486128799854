.notifications .input > input {
    color: white;
}

.notifications .ui.input > input:focus {
    color: white;
}

.notifications-page .notifications-toggle {
    margin-bottom: 27px;
}

