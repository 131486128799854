.reference {
  padding-top: 50px;
}

.reference .column .header {
  margin-top: 30px !important;
  margin-bottom: 30px;
}

.reference .column .filter-dropdown {
  display: flex;
  justify-content: space-around;
  margin-bottom: 50px;
}

.navbar-light .navbar-brand {
  color: white !important;
}

.reference .ui.selection.dropdown > .text {
  white-space: nowrap;
  width: 150.6px;
  overflow: hidden;
}

.reference .ui.selection.dropdown .menu {
  width: 196px;
  margin-left: -1px;
}

.reference .ui.selection.dropdown .menu > .item {
  width: 186px;
}

.reference .favourite-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: flex-start;
}

.reference .favourite-filter .dropdown.search {
  width: 196px;
  margin-right: 5px;
}

.favourites header {
  font-size: 14px;
  display: grid;
  grid-template-columns: 280px 1fr;
  margin-bottom: 10px;
  border: 3px solid #0f2439;
  color: #9cb3c9;
  height: 52px;
  align-items: center;
  padding: 0 12px;
  border-radius: 3px;
}

.favourites {
  max-width: 700px;
  margin: 0 auto 30px;
}

.favourite {
  margin: -10px -9px;
  display: grid;
  /* height: 100%; */
  align-items: center;
  grid-template-columns: 280px auto auto;
  min-height: 67px;
}

.reference-item .user,
.favourite .user {
  display: grid;
  grid-template-columns: auto 1fr;
  /* padding-right: 20px */
}

.reference-item .user .image,
.favourite .user .image {
  object-fit: cover;
  /* border-radius: 3px; */
  box-shadow: rgba(0, 0, 0, 0.18) 0px 5px 15px 0px;
  margin: auto 15px auto 0;
}

.reference-item .user-details,
.favourite .user-details {
  margin: auto 0;
}

.reference-item .user-details .name,
.favourite .user-details .name {
  font-size: 17px;
  font-weight: 600;
  color: #4183c4;
}

.reference-item .user-details .name:hover,
.favourite .user-details .name:hover {
  text-decoration: underline;
}

.reference-item .user-details .location a,
.favourite .user-details .location a {
  color: #9cb3c9;
  font-size: 14px;
}

.reference-item .user-details .location a:hover,
.favourite .user-details .location a:hover {
  color: white;
}

.reference-item .user-details .headline,
.favourite .user-details .headline {
  color: white;
  font-weight: normal;
}

.favourite textarea {
  margin: 0;
  width: 180px;
  padding: 0.57857143em 1em;
  line-height: 1.15;
  font-size: 14px;
  resize: none;
  min-height: 76px;
  border-radius: 2px;
}

.favourite textarea:focus {
  border-color: #4799eb;
  outline: 0 none;
}

.reference-item .save,
.favourite .control {
  margin: auto 0 auto auto;
}

.references {
  width: 650px;
  margin: 0 auto 30px;
}

.references header {
  font-size: 14px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 370px 1fr 1fr;
  margin-bottom: 10px;
  border: 3px solid #0f2439;
  color: #9cb3c9;
  height: 52px;
  align-items: center;
  padding: 0 12px;
  border-radius: 3px;
}

.reference-item {
  margin: -10px -9px;
  display: grid;
  /* height: 100%; */
  grid-gap: 20px;
  grid-template-columns: 370px 1fr 1fr;
}

.reference-item .year,
.reference-item .level,
.reference-item .association {
  margin: auto 0;
  color: white;
}

.skills .reference-item {
  grid-template-columns: auto auto;
}

.universities header,
.universities .reference-item {
  grid-template-columns: 300px 4fr 3fr 80px;
}

