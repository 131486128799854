.container {
  padding: 30px 30px;
  overflow-x: auto;
}

.container :global(.ui.table) tr td {
  padding: 6px 10px ;
  height: 54px;
}

.container :global(.ui.table) {
  background-color: #1f364d;
  margin-bottom: 20px;
}

.container :global(.ui.table) tr:hover, .container :global(.ui.table) tr.active {
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}


.container table input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  font-family: inherit;
  height: 100%;
}



.container table input.error {
  border: 1px solid red !important;
}

.container table :global(.ui.button) {
  margin: 0;
}

.container :global(.ui.toggle.checkbox) {
  margin-left: 20px;
}

.container :global(.ui.toggle.checkbox input:checked ~ label) {
  color: #fff !important;
}

.error {
  border: 1px solid red;
}