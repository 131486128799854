.ui.container.legal-page {
  max-width: 750px !important;
  margin-top: 36px;
  margin-bottom: 36px;
  /* overflow: auto; */
  height: 100%;
}

.legal-page h1 {
  margin-bottom: 36px;
  line-height: 1.3;
  font-size: 40px;
  font-weight: 400;
}

.legal-page h2 {
  margin-top: 27px;
  font-size: 20px;
}

.legal-page p,
.legal-page ul {
  color: #9cb3c9;
  font-size: 18px;
  line-height: 1.4em;
}

.legal-page ul {
  padding-inline-start: 20px;
}

.legal-page p:not(:first-child) {
  margin-top: 15px;
}

.legal-page a {
  color: white;
}

.legal-page ol li {
  font-size: 17px;
  line-height: 26px;
  text-decoration: underline;
  list-style-type: none;
}

.legal-page ol li a:hover {
  font-weight: 600;
}

.legal-page p a:hover {
  text-decoration: underline;
}

.navbar.legal-nav .ui.container {
  max-width: 750px !important;
}

.terms.require-password {
  padding-right: 10px;
}

.terms.require-password .ui.form .field {
  margin-bottom: 0;
}

.terms.require-password > .ui.ui.container > div {
  margin-top: 0;
}

.lpnav-wrapper.term-nav {
  position: unset;
}

@media (max-width: 767px) {
  .legal-page h1 {
      font-family: "Source Sans Pro", sans-serif !important;
      font-size: 30px;
  }
}
