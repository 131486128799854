.landing-page-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  color: white;
}
input.PhoneInputInput {
  border: 0px solid #0f2439 !important;
}

.landing-page-bg {
  transition: background-image 20000ms ease-in-out;
  background-color: #00407a;
  background-blend-mode: multiply;
  background-size: cover;
  background-position-x: 90%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  height: 100%;
  padding-bottom: 45px;
}


.slide *,
.lpnav *:not(i) {
  font-family: 'Helvetica Neue', HelveticaNeue, Helvetica, Arial, sans-serif !important;
}

.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 720px;
}

.landing-page .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-page .logo-container img {
  width: 80px;
  height: 80px;
}

.landing-page .tagline-container {
  text-align: center;
  padding: 0 26px;
  /* margin-top: 10em; */
}

.landing-page .tagline-container h2 {
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0 16px 0;
  line-height: 22px;
}

.landing-page .form.signup .terms-warning {
  text-align: center;
  font-size: 16px;
  width: 100%;
  line-height: 22px;
  padding: 5px 0;
  display: inline-block;
}

.new-tagline-container {
  /* text-align: center; */
  padding: 0 26px;
  margin-top: 10em;
}

.new-tagline-container .major-text {
  font-weight: 400;
  font-size: 32px;
  margin: 10px 0 16px 0;
  line-height: 1.2em;
}
.new-tagline-container .minor-text {
  font-weight: 600;
  font-size: 21px;
  margin: 10px 0 16px 0;
  line-height: 1.2em;
  color: #9cb3c9;
}

.landing-page .form-toggler {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.landing-page .form-toggler button {
  width: 100%;
  height: 45px;
  color: white;
  font-weight: 600;
  text-transform: capitalize;
  border: none;
  margin-top: 12px;
  font-size: 16px;
  border-radius: 2px;
}

.landing-page button.landing-secondary {
  background: white;
  color: #444;
}

.landing-page button.landing-secondary:hover {
  background: white;
  color: #444;
}

.landing-page .toggler {
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin-bottom: 7px;
  line-height: 17px;
}

.landing-page .toggler a {
  margin: 0 5px;
}

.landing-page .form {
  width: 100%;
}

.landing-page .form h3 {
  text-align: center;
  margin: 10px 0;
}

.landing-page .form .field,
.landing-page .form .field button {
  margin-bottom: 10px;
}

.landing-page .form .field .required {
  height: 44px;
  margin: 0;
}

.landing-page .form .input {
  display: inline-block;
}

.landing-page .form .field .ui.input input {
  width: 100% !important;
  border-radius: 2px;
  font-size: 16px;
  padding: 14px;
  height: 44px;
  /* border: 0.5px solid #3f88d3 !important;
  box-shadow: none !important;
  outline: 0 !important; */
}

/* form input */
form{
  font-family: 'Source Sans Pro', Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
}
.form-input-field-all {
  margin-bottom: 10px;
}

.form-input-all {
  font-family: 'Source Sans Pro', Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  margin: 0 !important;
  outline: 0 !important;
  -webkit-appearance: none !important;
  /* tap-highlight-color: hsla(0, 0%, 100%, 0) !important; */
  line-height: 1.21428571em !important;
  padding: 0.60714286em 1em !important;
  font-size: 1em !important;
  background: 0 0 !important ;
  border: 1px solid #0e2439 !important;
  color: #fff !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  transition: color 0.1s ease, border-color 0.1s ease !important;
  width: 100% !important;
  font-size: 16px !important;
  padding: 14px !important;
  height: 44px !important;
}

.form.signup .ui.checkbox {
  margin: 10px 0;
  -webkit-tap-highlight-color: transparent;
}

.form.signup .ui.checkbox label {
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
}

.form.signup .ui.checkbox label:before {
  top: 4px;
  border: 1px solid #0e2439;
}

.form.signup .ui.checkbox.chec label:before {
  border: 1px solid #0e2439;
}

.form.signup .ui.checkbox label:after {
  top: 2px;
}



.form-input-all:focus {
  font-family: 'Source Sans Pro', Lato, 'Helvetica Neue', Arial, Helvetica,
    sans-serif;
  margin: 0 !important;
  outline: 0 !important;
  -webkit-appearance: none !important;
  /* tap-highlight-co.lor: hsla(0, 0%, 100%, 0) !important; */
  line-height: 1.21428571em !important;
  padding: 0.60714286em 1em !important;
  font-size: 1em !important;
  background: 0 0 !important ;
  border: 1px solid #0080ff !important;
  color: #fff !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  transition: color 0.1s ease, border-color 0.1s ease !important;
  font-size: 16px !important;
  padding: 14px !important;
  height: 44px !important;
}

input::placeholder {
  color: #63809c;
}
/* end form input */

/* a {
  color: #0d6efd !important;

} */

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  font-family: "Source Sans Pro", Lato, "Helvetica Neue", Arial, Helvetica,
    sans-serif;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: hsla(0,0%,100%,0);
  line-height: 1.21428571em;
  padding: 0.60714286em 1em;
  font-size: 1em;
  background: 0 0 !important;
  border: 1px solid rgba(15,36,57,.8705882352941177);
  color: #fff !important;
  border-radius: 0.25px;
  box-shadow: none;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.ui.form select {
  background: white !important;
  color: #0f2439 !important;
}

.landing-page .form .button {
  display: flex;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  align-items: center;
}

.landing-page .form button {
  width: 100%;
  margin: 0;
  padding: 0 !important;
  border: none !important;
  height: 45px !important;
  font-size: 16px;
  border-radius: 2px !important;
}

.landing-page code {
  background-color: #262626;
  padding: 0 5px;
  font-family: 'Consolas', Courier, monospace !important;
  color: #4799eb;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-decoration: none;
  margin-top: -1px;
}

.landing-page .footer {
  margin-bottom: 5px;
  margin-left: auto;
  padding: 0 10px 0 10px;
}

.lpnav .icons-container a {
  color: white;
  font-size: 16px;
  margin: 0 10px;
}

.lpnav .icons-container,
.lpnav .navbar-buttons {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.lpnav .navbar-buttons .ui.button {
  font-size: 14px;
}

.lpnav .navbar-buttons .ui.button.primary {
  background: rgba(0, 0, 0, 0);
}

.landing-page .legal-links {
  margin-left: auto;
}

.landing-page .legal-links a {
  color: white;
}

.landing-page .legal-links a:hover {
  text-decoration: underline;
}

.landing-page .width-limit-wrapper {
  width: 300px;
}

.landing-page .scroll-hint {
  height: 45px;
  background-color: #001a35;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
}

.scroll-hint-4 {
  position: absolute;
  bottom: 0;
  height: 45px;
  background-color: #00407a;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  cursor: pointer;
  width: 100%;
}

.lpnav-wrapper {
  position: fixed;
  z-index: 9999999;
  width: 100%;
}

.lpnav-wrapper.terms-nav {
  position: initial;
  margin-bottom: 5px;
}

.lpnav {
  width: 100%;
  min-width: 320px;
  max-width: 1580px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.lpnav > a {
  display: flex;
  align-items: center;
  margin: 11px 0 6px 22px;
}

.lpnav .icp-name {
  margin-left: 16px;
  font-size: 14px;
  color: white;
  font-weight: 700;
  white-space: nowrap;
}

.slider {
  position: relative;
  width: 100%;
  min-width: 320px;
  height: 100%;
  overflow: hidden;
}

.track {
  height: 100%;
}

.slide {
  width: 100%;
  padding-top: 51px;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.slide.hidden {
  top: -100%;
  visibility: hidden;
  transition: top 0;
}

.slide.previous {
  top: -100%;
}

.slide.current {
  top: 0;
}

.slide.next {
  top: 0;
  visibility: hidden;
}

.slide.animateIn,
.slide.animateOut {
  transition: top 0.7s cubic-bezier(0.825, 0, 0.5, 1);
}

.slide.animateIn.previous,
.slide.animateIn.next {
  top: 0;
  visibility: visible;
}

.slide.animateOut.previous {
  top: 0;
  z-index: -2;
}

.slide.animateOut.next {
  top: -100%;
  z-index: 999;
  visibility: visible;
}

.lp-slide-controller {
  /* display: none; */
  position: fixed;
  height: fit-content;
  z-index: 2;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.lp-slide-controller * {
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px #fff;
  cursor: pointer;
  height: 16px;
  margin-bottom: 10px;
  opacity: 0.4;
  position: relative;
  width: 16px;
  box-sizing: border-box;
}

.lp-slide-controller *:hover {
  opacity: 1;
}

.lp-slide-controller .active {
  background: #fff;
  box-shadow: none;
  opacity: 1;
}

.slider h1 {
  font-size: 41.9534px;
  line-height: 1;
  margin: 0 0 15px;
  font-weight: 400;
  font-family: Gibson, 'Helvetica Neue', HelveticaNeue, Helvetica, Arial,
    sans-serif !important;
}

.slider p {
  font-size: 18.5185px;
  line-height: 25.9259p;
  font-weight: 400;
}

.slide.s2 {
  text-align: center;
  /* Background color slide 2 */
  background: #00cf35;
}

.slide.s2 img {
  height: 440px;
  margin: 0 auto -78px;
  overflow: visible;
  position: relative;
  box-sizing: border-box;
}

.slide.s2 .s2-wrapper {
  margin: auto;
  width: 700px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.slide.s3 {
  background: #7c5cff;
  display: grid;
  grid-template-rows: auto 1fr;
}

.slide.s3 .s3-wrapper {
  margin-left: 180px;
  margin-top: 99px;
  margin-bottom: 20px;
  width: 500px;
  height: 100%;
  max-width: 100%;
}

.slide.s3 img {
  margin-top: 10px;
  margin-left: 180px;
}

.slide.s4 {
  display: table-cell;
  vertical-align: middle;
  background: #001935;
}

.slide.s4 .s4-wrapper {
  position: absolute;
  height: fit-content;
  left: 50%;
  margin-left: 83.3333px;
  width: 315px;
  height: 1488px;
  min-height: calc(100% - 96px);
  max-height: calc(100% - 96px);
  top: 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide.s4 .s4-wrapper.image-centered {
  display: flex;
  align-items: center;

  width: 360px;
  margin-left: -316.667px;
}

.slide.s4 .s4-wrapper.image-centered img {
  margin: auto;
}

.slide.s4 .images {
  left: 50%;
  margin-left: -440px;
  position: absolute;
  top: 110px;
  width: 540px;
  transform-origin: top;
}

.slide.s4 .images.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: calc(100% - 110px);
  transform-origin: top;
}

.slide.s4 img {
  width: 100%;
  margin-bottom: 10px;
}

.slide.s4 .centralize {
  margin: auto;
}

@media only screen and (min-width: 1600px) {
  .slide.s3 .s3-container,
  .slide.s3 img {
    margin-right: auto;
    margin-left: auto;
    width: 1260px;
  }
  .slide.s3 .s3-wrapper {
    margin-left: 0;
  }
}

@media only screen and (max-height: 700px),
  only screen and (max-width: 1100px) {
  .slide h1 {
    font-size: 41.9534px;
  }

  .slide.s2 .s2-wrapper {
    width: 600px;
  }

  .slide.s2 img {
    height: 320px;
    margin-bottom: -50px;
  }

  .slide.s3 .s3-wrapper {
    margin-left: 140px;
    margin-top: 69px;
  }

  .slide.s3 img {
    margin-left: 140px;
  }

  .slide.s4 .s4-wrapper {
    margin-left: 83.3333px;
  }

  .slide.s4 .s4-wrapper:not(.image-centered) {
    max-width: 36vw;
  }

  .slide.s4 .s4-wrapper.image-centered {
    /* transform: scale(0.7); */
    width: 360px;
    margin-left: -316.667px;
  }

  .slide.s4 .images {
    transform: scale(0.7);
  }
}

/* @media only screen and (max-height: 540px), only screen and (max-width: 900px) {
  .slide h1 {
    font-size: 34px;
  }

  .slide.s2 .s2-wrapper {
    width: 540px;
  }

  .slide.s2 img {
    height: 220px;
    margin-bottom: -30px;
  }

  .slide.s4 .s4-wrapper {
    margin-left: 50px;
    width: 250px;
    height: 744px;
  }

  .slide.s4 .s4-wrapper.image-centered {
    width: 270px;
    margin-left: -240px;
  }

  .slide.s4 .images {
    transform: scale(0.5);
    margin-left: -375px;
  }
} */

@media only screen and (max-width: 900px) {
  .slide h1 {
    font-size: 4.6614872vw
  }

  .slider p {
    font-size: 2.0576131vw;
    line-height: 2.8806574vw;
  }

  .slide.s2 .s2-wrapper {
    width: 70vw;
  }

  .slide.s2 img {
    height: 40.74074074074074vw;
  }

  .slide.s3 .s3-wrapper {
    width: 41.9533851vw;
    margin-left: 39.8446171vw;
  }

  .slide.s3 img {
    margin-left: 25.925925925925927vw;
  }

  .slide.s4 .s4-wrapper {
    width: 35vw;
    margin-left: 9.25925925925926vw;
    height: 110.22222222222224vw;
  }
  
  .slide.s4 .images {
    transform: none;
    width: 40vw;
    margin-left: -35.18518518518518vw;
  }
  
  .slide.s4 .s4-wrapper.image-centered {
    transform: none;
    width: 40vw;
    margin-left: -35.18518518518518vw;
  }

}

@media screen and (max-width: 540px) {
  .lp-slide-controller {
    position: fixed;
    z-index: 2;
    top: unset;
    bottom: 40px;
    left: 51%;
    transform: translateX(-50%) rotate(-90deg);
  }

  .lpnav .icp-name:not(.name-on-mobile) {
    display: none;
  }

  .lpnav .navbar-buttons.name-on-mobile .icons-container {
    display: none;
  }

  .lpnav .navbar-buttons.name-on-mobile .signup-button {
    display: none;
  }

  .lpnav .ui.button {
    padding: 10px;
  }

  .lp-slide-controller *:last-child {
    margin-bottom: 0;
  }

}


@media only screen and (max-width: 540px) {
  .slide h1 {
    font-size: 25.17203088px;
  }

  .slider p {
    font-size: 11.11111111111111px;
    line-height: 15.555555555555554px;
  }

  .slide.s4 .s4-wrapper {
    width: 189px;
    /* margin-left: 50px; */
    margin-left: 0;
    transform: translateX(3vw)
  }

  .slide.s4 .s4-wrapper.image-centered {
    /* width: 150px; */
    /* margin-left: -131px; */
    width: 40vw;
    margin-left: 0;
    transform: translateX(calc(-100% - 1vw));
  }
}


@media (max-height: 500px) {
  .lp-slide-controller {
    display: none;
  }
}


@media screen and (max-width: 375px) {

   .slide h1 {
    font-size: 6.7125416vw;
  }

  .slider p {
    font-size: 2.962962962962963vw;
    line-height: 4.148148148vw;
  }
}