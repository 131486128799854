.container {
  width: 100%;
  padding: 71px 0 10px 0px;
  height: 100%;
}

.limiter {
  margin: 0 auto;
  height: 100%;
}
