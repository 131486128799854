.container {
  width: 100%;
  padding: 0px 10px 10px 10px;
  height: 100%;
}

.limiter {
  max-width: 800px;
  margin: 0 auto;
}

.wrapper {
  position: relative;
  width: 100%;
  /* height: 0; */
  padding-bottom: 56.25%;
  overflow: hidden;
}

.wrapper > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}