
.container {
  padding: 30px 30px;
  overflow-x: auto;
}

.container :global(.ui.table) tr td {
  padding: 6px 10px ;
  height: 54px;
}

.container :global(.ui.table) {
  background-color: #1f364d;
  margin-bottom: 20px;
}

.container :global(.ui.table) tr:hover, .container :global(.ui.table) tr.active {
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.container table input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  color: white;
  font-family: inherit;
  height: 100%;
}


.container .edit :global(.ui.search.dropdown),
.container .edit :global(.ui.search.dropdown > input.search),
.container .edit .categories:global(.ui.multiple.dropdown) {
  width: 100% !important;
  min-width: 100% !important;
  background-color: transparent;
  border: none !important;
  box-shadow: none;
  padding: 0;
  padding-right: 25px;
  color: white;
  height: 100%;
}

.container .edit :global(.ui.search.dropdown > .text),
.container .edit .categories:global(.ui.multiple.dropdown > .text) {
  line-height: 40px;
  white-space: nowrap;
}

.container .edit :global(.ui.search.dropdown > .dropdown.icon),
.container .edit .categories:global(.ui.multiple.dropdown > .dropdown.icon) {
  line-height: 22px;
  padding-right: 0;
}

.container .edit .categories:global(.ui.multiple.dropdown > .text) {
  margin: 0;
}

.container .edit .categories {
  padding-left: 0;
}

.container .edit .categories:global(.ui.multiple.dropdown) > :global(.ui.label) {
  background-color: #0c1f31;
  color: white;
  padding-right: 0;
  white-space: nowrap;
}

.container .edit .categories:global(.ui.multiple.dropdown) > :global(.ui.label):hover {
  filter: brightness(0.9);
}

.container .edit .categories:global(.ui.multiple.dropdown) > :global(.ui.label) i {
  margin: 0;
}

.container .edit .categories:global(.ui.multiple.dropdown) > :global(.ui.label) i:hover {
  color: red
}

.container table input.error,
.container .edit :global(.ui.search.dropdown).error {
  border: 1px solid red !important;
}

.container table :global(.ui.button) {
  margin: 0;
}


.filters {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-top: -10px;
  border: none;
  border-radius: 3px;
  margin-bottom: 20px;
}

.filters button {
  white-space: nowrap;
  margin: 10px 20px 0 0 !important;
  height: 36px;
}


.filters :global(.ui.input) input, .filters :global(.ui.search.dropdown > input.search) {
  border-color: #2a4056 !important;
  color: #fff;
}

.dropdownWrapper, .searchWrapper {
  display: flex;
  margin: 0 20px 0 0;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 200px;
  max-width: 100%;
  height: 100%;
}

.dropdownWrapper :global(.ui.search.selection.dropdown), .searchWrapper :global(.ui.input) {
  width: 200px !important;
  min-width: 200px !important;
}

.dropdownWrapper > span, .searchWrapper > span {
  margin: 10px 0 4px 0;
}


.container :global(.ui.table) tr td:nth-last-child(1):empty,
.container :global(.ui.table) tr td:nth-last-child(2):empty, 
.container :global(.ui.table) tr td:nth-last-child(3):empty {
  padding: 0 !important;
}

@media screen and (max-width: 767px) {
  :global(.ui.table) thead.tableHeader {
      display: none; 
  }

  .container :global(.ui.table) tr td {
      padding: 10px 0 ;
      min-height: 30px;
      height: unset;
  }

  .container :global(.ui.table) tr td span {
      font-weight: bold;
  }

  .container :global(.ui.table) tr td:nth-last-child(1),
  .container :global(.ui.table) tr td:nth-last-child(2), 
  .container :global(.ui.table) tr td:nth-last-child(3) {
      float: left;
  }

  .container :global(.ui.table) tr {
      padding: 20px 10px 60px 10px;
  }

  .container .edit :global(.ui.search.dropdown > input.search) {
      border: 1px solid #0c1f31 !important;
      padding: 8.5px 14px;
  }

  .container .edit .categories:global(.ui.multiple.dropdown) {
      border: 1px solid #0c1f31 !important;
      padding: 2px 14px;
  }

  .container .edit .categories:global(.ui.multiple.dropdown) > :global(.ui.label) {
      margin-top: 5px
  }

  .container .edit :global(.ui.search.dropdown) {
      padding: 2px 14px;
  }        

  .container .edit :global(.ui.search.dropdown > .text) {
      line-height: 30px;
      top: 5px;
      left: 3px;
  }

  .container .edit :global(.ui.search.dropdown > .dropdown.icon) {
      line-height: 25px;
      padding-right: 0;
  }

  .container table input {
      padding: 8.4px 14px;
      border: 1px solid #0c1f31;
      margin-top: 4px;
  }

  .container :global(table.ui.table) tr td.edit {
      padding: 0 10.5px 15px 10.5px !important;
      min-height: 20px !important;
  }
}