.subscription .ui.selection.dropdown .menu {
    margin-top: 0 !important;
    max-height: 16.02857142rem;
    background-color: #0e2439;
    border: 1px solid #1f364d !important;
    border-radius: 0.28571429rem;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
    margin-top: 3px !important;
}

.subscription .ui.selection.dropdown .menu::-webkit-scrollbar {
    width: 0.4em;
}

/* Handle */
.subscription .ui.selection.dropdown .menu::-webkit-scrollbar-thumb {
    background: #9cb3c9;
}

.subscription .ui.dropdown .item[data-id="default"] {
    display: none;
}

/* .subscription .ui.selection.dropdown .default.text {
    visibility: hidden;
    position: relative;
}

.subscription .ui.selection.dropdown .default.text::after {
    visibility: visible;
    content: "Search for organizers";
    position: absolute;
    top: 0;
    left: 0;
}

.subscription .ui.selection.dropdown .default.text.filtered::after {
    visibility: hidden;
} */