html {
  overflow-y: auto;
  overflow-x: hidden;
}

body {
  /* min-height: 100% !important;
  height: unset !important; */
  min-width: 100vw;
  /* min-width: 320px; */
  /* overflow-x: auto; */
}

#root {
  width: 100%;
  /* min-height: 100vh; */
  height: 100% !important;
  min-width: 320px;
  overflow-x: hidden !important;
  position: relative;
}

a,
a * {
  cursor: pointer;
}

.center {
  text-align: center;
}

.email-verification {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  color: #9cb3c9;
  font-size: 13px;
  font-weight: bold;
  background: black;
  text-align: center;
}

.email-verification .button {
  margin: 0 20px;
  padding: 6px 7px;
  font-weight: 600;
}

.entities-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.feed_events_wrapper{
  margin-top: -5px;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; /* border: 3px solid #1f364d; */
  border-bottom: 3px solid #1f364d;
  border-right: 3px solid #1f364d;
  border-left: 3px solid #1f364d;
  box-shadow: 0 0 28px rgb(0 0 0 / 7%);
  margin-top: -3px;
  max-width: 700px;
}

/* .entities-wrapper .entity-label.ui.basic.label,
.entity-label.ui.basic.label {
  border: 1px solid #0f2439;
  border-radius: 3px;
  background-color: transparent;
  position: relative;
  color: white;
  padding: 8px 18px;
  margin: 5px 10px 5px 0;
  font-weight: 700;
} */

.ui.basic.button {
  /* box-shadow: inset 0 0 0 1px #0f2439de !important */
  box-shadow: none !important;
  border: 1px solid #0f2439de !important;
  min-height: calc(1em - 2px);
  line-height: calc(1em - 2px);
}

.ui.basic.button:hover {
  box-shadow: none !important;
  border-color: #4799eb !important;
}

.entities-wrapper .entity-label.ui.basic.label,
.entity-label.ui.basic.label {
  /* border: 1px solid #1f364d !important; */
  border-radius: 3px;
  background-color: initial;
  position: relative;
  color: #fff;
  padding: 8px 18px;
  margin: 5px 10px 5px 0;
  font-weight: 700;
  font-size: 12px !important;
}

.entities-wrapper .entity-label.ui.basic.label:hover,
.entity-label.ui.basic.label:hover {
  border-color: #4799eb !important;
}

.entities-wrapper .entity-label.ui.basic.label .remove span,
.entity-label.ui.basic.label .remove span {
  width: 20px;
  height: 20px;
  position: absolute;
  color: white;
  background: #0f2439de;
  border-radius: 99999px;
  top: -10px;
  right: -10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entities-wrapper .entity-label.ui.label.basic .remove span:hover,
.entity-label.ui.label.basic .remove span:hover {
  cursor: pointer;
}

.entities-wrapper .entity-label.ui.basic.label:hover .remove span,
.entity-label.ui.basic.label:hover .remove span {
  background-color: #4799eb;
}

.backend-ui.entity-label.ui.basic.label {
  border: 1px solid #0f2439 !important;
}

.ui.search.swa .input {
  display: flex;
  width: 250px !important;
  max-width: 100%;
}

.location-form input,
.ui.search.swa .prompt {
  border-radius: 3px !important;
  color: white;
}

.location-form input:focus,
.ui.search.swa .prompt:focus {
  border-color: #85b7d9;
  box-shadow: none;
  outline: 0 none;
}

.location-form .results,
.ui.search.swa .results {
  background-color: #0e2439;
  border-color: #1f364d;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  width: 250px;
  max-width: 100%;
  z-index: 9999999999;
}

.location-form .results .message.empty .header *,
.ui.search.swa .results .message.empty .header * {
  font-size: 14px;
  font-weight: 500;
}

.location-form .result,
.ui.search.swa .result {
  padding: 9.692307692307692px 12.923076923076923px;
}

.location-form .result:hover,
.location-form .result:active,
.ui.search.swa .result:hover,
.ui.search.swa .result.active {
  background-color: #4799eb;
}

.location-form .menu-item-swa-wrapper,
.ui.search.swa .menu-item-swa-wrapper {
  color: white;
}

.location-form .menu-item-swa-wrapper .name,
.ui.search.swa .menu-item-swa-wrapper .name {
  font-size: 14px;
  line-height: 18.307692307692307px;
}

.location-form .menu-item-swa-wrapper .count,
.ui.search.swa .menu-item-swa-wrapper .count {
  font-size: 10.76923076923077px;
  line-height: 1;
}

.ui.search.swa .result .swa-addition-wrapper {
  margin: -0.85714286em -1.14285714em;
  padding: 0.85714286em 1.14285714em;
  background-color: #0e2439;
}

.location-form .result {
  padding: 12px 16px;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 100;
  background-color: #0e2439;
}

.reference .ui.search.dropdown > input.search,
.heading .control .ui.search.swa .ui.input > input,
.onboarding-form-wrapper.withoutbg input {
  border-color: #1f364d !important;
  color: white;
}

.heading .control .ui.search.swa .ui.input > input:focus {
  border-color: #4799eb !important;
}

.ui.dropdown .menu .selected.item:hover {
  background-color: #4799eb;
}

.ui.secondary.button {
  border: 3px solid #0f2439;
  padding-top: 7px;
  padding-bottom: 7px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

.page-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #0e2439;
  z-index: 9999;
}

/* people filter */
.people-filter-box .search-count:empty {
  display: none;
}

.people-filter-box .clear-wrapper {
  margin: auto 10px auto auto;
}

.people-filter-box .clear-wrapper .button {
  margin: auto;
}

.people-filter .ui.input input {
  display: flex;
  width: 100%;
}

/* .ui.search-popup.popup {
  padding: 25px;
} */

.people-filter .checkbox-wrapper {
  padding: 10px 5px 10px;
}

.people-filter .mini-menu > .button {
  display: block;
  margin-left: auto;
}

.people-filter .mini-menu li {
  list-style: none;
  margin-bottom: 10px;
}

.ui.search.swa .input {
  width: 250px;
  max-width: 100%;
}

.location-form input,
.ui.search.swa .prompt {
  border-radius: 3px;
  color: white;
}

.location-form input:focus,
.ui.search.swa .prompt:focus {
  border-color: #85b7d9;
  box-shadow: none;
  outline: 0 none;
}

.location-form .results,
.ui.search.swa .results {
  background-color: #0e2439;
  border-color: #1f364d;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  width: 250px;
  max-width: 100%;
  z-index: 9999999999;
}

.location-form .results .message.empty .header *,
.ui.search.swa .results .message.empty .header * {
  font-size: 14px;
  font-weight: 500;
}

.location-form .result,
.ui.search.swa .result {
  padding: 9.692307692307692px 12.923076923076923px;
}

.location-form .result:hover,
.location-form .result:active,
.ui.search.swa .result:hover,
.ui.search.swa .result.active {
  background-color: #4799eb;
}

.location-form .menu-item-swa-wrapper,
.ui.search.swa .menu-item-swa-wrapper {
  /* border: 3px solid #1f364d; */
  /* padding: 5px; */
  color: white;
}

.location-form .menu-item-swa-wrapper .name,
.ui.search.swa .menu-item-swa-wrapper .name {
  font-size: 14px;
  line-height: 18.307692307692307px;
}

.location-form .menu-item-swa-wrapper .count,
.ui.search.swa .menu-item-swa-wrapper .count {
  font-size: 10.76923076923077px;
  line-height: 1;
}

.ui.search.swa .result .swa-addition-wrapper {
  margin: -0.85714286em -1.14285714em;
  padding: 0.85714286em 1.14285714em;
  background-color: #0e2439;
}

/*  show menu for screen sizes <= 766px and hide other menus */
@media (max-width: 766px) {
  .tablet-and-up {
    display: none !important;
  }
  /* .feed-menu-visible {
    display: none !important;
  } */
}

/*  show menu for screen size 767 and hide other menus */
@media (min-width: 767px) and (max-width: 768px) {
  .tablet-and-up {
    display: none !important;
  }
  /* .feed-menu-visible {
    display: none !important;
  } */
  .navbar .nav-menu .menu-icon {
    margin-right: 30px;
  }


}

@media (max-width: 768px) {
  .feed_events_wrapper{
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-bottom: 20px;
    margin-top: 0px;
    max-width: 700px;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-m-and-up {
    display: none !important;
  }

  .feed_events_wrapper{
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-bottom: 20px;
    margin-top: 0px;
    max-width: 700px;
  }
}
  


/* to make mobile only views visible. Show other menus for screen size >= 768  */
@media (min-width: 768px) {
  .mobile-only {
    display: none !important;
  }
  .tablet-and-up {
    display: block !important;
  }
  .feed-menu-visible {
    display: block !important;
  }
}

@media (max-width: 1023px) {
  .mobile-tablet-only {
    display: none !important;
  }
}

.react-phone-number-input {
  position: relative;
}

.react-phone-number-input__country {
  position: absolute !important;
  top: 11px;
  left: 10px !important;
  border: none;
}
.ui.form input.react-phone-number-input__input {
  height: unset !important;
  padding-left: 50px;
}

.react-phone-number-input__icon--international,
.react-phone-number-input__country-select-arrow {
  color: white;
}

.react-phone-number-input__country-select {
  color: black !important;
}

.react-phone-number-input__country {
  position: absolute !important;
  top: 11px;
  left: 10px;
  border: none;
}

.react-phone-number-input__icon {
  display: grid;
  align-items: center;
}

.checkbox-with-link {
  display: flex;
}

.checkbox-with-link .checkbox {
  margin-right: 8px;
}

.checkbox-with-link a {
  text-decoration: underline;
}

.checkbox-with-link .ui.fitted.checkbox::after {
  display: none;
}

.bubbleInput.entity-label.ui.basic.label {
  font-size: 1em;
  padding: calc(0.60714286em - 3px) 1em;
  margin: 0;
  border: 1px solid #0f2439;
  border-radius: 0.25px;
  box-sizing: border-box;
  line-height: 1.21428571em;
  /* position: absolute;
  transform: scale(.8);
  top: -2px;
  background-color: #233b53;
   */
}

.bubbleInput.entity-label.ui.basic.label:hover {
  border-color: #4799eb;
}

textarea {
  background: 0 0;
  border: 1px solid #0f2439;
  border-radius: 0.25px;
  outline: 0;
  color: #fff;
  border-radius: 0.14285714rem;
}

.buttonLink {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #4183c4;
  cursor: pointer;
}

/*  css for drop down icon */
i.icon {
  display: inline-block !important;
  opacity: 1 !important;
  /* margin: 0 0.25rem 0 0 o !important; */
  width: 2.5em !important;
  height: 100% !important;
  font-family: Icons !important;
  font-style: normal !important;
  font-weight: 400 !important;
  text-decoration: inherit !important;
  text-align: center !important;
  speak: none !important;
  font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
}

/* css for drop down input */
.ui.search.dropdown > input.search {
  background: none transparent !important;
  border: 1px solid #0f2439 !important;
  box-shadow: none !important;
  border-radius: 3px;
}

.ui.search.dropdown > input.search:focus {
  background: none transparent !important;
  border: 1px solid #4183c4 !important;
  box-shadow: none !important;
}

/* css for for drop down menu */
.location-form .results,
.ui.selection.dropdown .menu {
  background-color: #0e2439;
  border-color: #1f364d;
  border-radius: 0.28571429rem;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
  width: 250px;
  max-width: 100%;
  z-index: 9999999999;
  /* margin-top: 10px; */
}
.ui.search > .menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: center top;
  white-space: normal;
  text-align: left;
  text-transform: none;
  background: #fff;
  margin-top: 0.5em !important;
  width: 18em;
  /* border-radius: 0.14285714rem; */
  box-shadow: 0 2px 4px 0 rgb(34 36 38 / 12%), 0 2px 10px 0 rgb(34 36 38 / 15%);
  /* border: 1px solid #d4d4d5; */
  border: none !important;
  z-index: 998;
}
.transition {
  animation-iteration-count: 1;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
.ui.search {
  font-size: 1em;
  width: 250px !important;
}

/* for changing the styles of phone input icon */
.PhoneInputCountrySelectArrow {
  display: block !important;
  content: '' !important;
  width: 0 !important;
  height: 0 !important;
  margin-bottom: 0.1em !important;
  margin-top: 0.3em !important;
  margin-left: 0.3em !important;
  border-bottom-width: 0 !important;
  border-top-width: 0.35em !important;
  border-bottom-style: solid !important;
  border-top-style: solid !important;
  border-left: 0.2em solid transparent !important;
  border-right: 0.2em solid transparent !important;
  color: #b8bdc4 !important;
  opacity: 0.7 !important;
  transition: color 0.1s !important;
  transform: rotate(0deg) !important;
}

/* css for nanobar.js progress bar */
.nanobar .bar {
  background: #4799eb;
  height: 3px;
}

.ui.icon.input > i.icon {
  cursor: default;
  position: absolute;
  line-height: 1;
  text-align: center;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
  width: 2.52857143em;
  opacity: 0.5 !important;
  border-radius: 0 0.25px 0.25px 0;
  transition: opacity 0.3s ease;
}

.ui.icon.input > input:focus ~ i.icon {
  opacity: 1 !important;
}

.page-count-input {
  background: #ffffff;
  border: none;
  box-shadow: 0px 3.23792px 29.6039px rgba(0, 0, 0, 0.07);
  border-radius: 2.77536px;
  outline: none;
  width: 156px;
 padding: 0.6em 0.5em 0.6em;
}
.page-count-submit {
  /* border: 1px solid #274562 !important; */
  margin-left: 10px !important;
}
.page-count-submit:focus {
  border: 2px solid #274562 !important;
  margin-left: 10px !important;
}

#notification-wrapper .toast-notification, .nanobar {
  z-index: 2147483647 !important;
}

.ui.form .field > .selection.dropdown > .dropdown.icon {
  font-family: Dropdown, Icons, FontAwesome !important;
}

.hidden {
  display: none;
}

.ui.dropdown .menu > .item {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 10px;
  row-gap: 4px;
  /* grid-template-areas: "image text" 
                       "image description"; */
}

.ui.dropdown .menu > .item > .image {
  width: 2em !important;
  /* margin: -0.3em 0.4em -0.3em 0; */
  margin: 0;
  height: 2em !important;
  grid-column: 1/2;
  grid-row: 1/3;
  align-self: center;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}

.ui.dropdown .menu > .item > .text {
  grid-column: 2/3;
  grid-row: 1/2;
  font-weight: 600;
}

.ui.dropdown .menu > .item > .description {
  grid-column: 2/3;
  grid-row: 2/3;
  float: none !important;
  color: #9cb3c9 !important;
  /* margin-top: 0.5em !important; */
  margin-left: 0;
  
}

.ui.dropdown .menu > .item:hover > .description {
  color: #ffffff !important;
}

.ui.dropdown .menu > .item > .text b {
  text-transform: capitalize;
  font-weight: 600;
}

.ui.dropdown .menu .item.suggestion {

  padding: 0 !important;
  width: fit-content;
  display: block;
  margin: 0.78571429rem 1.14285714rem !important
}

.ui.dropdown .menu .item.suggestion:hover {
  background: rgba(0, 0, 0, 0);
}

.ui.dropdown .menu .selected.item.suggestion:hover {
  background: rgba(0, 0, 0, 0);
}

.ui.modal.delete-confirm {
  background-color: #0e2439;
}

.ui.modal > .header,
.ui.modal > .content,
.ui.modal > .actions {
  background-color: #0e2439;
  color: #fff;
  padding: 20px !important;
  font-size: 16px;
}

.ui.modal > .actions > .ui.button {
  background-color: transparent;
  color: #fff;
  border: 1px solid #1f364d;
}

.ui.modal > .actions > .ui.button.primary {
  background-color: #4799eb;
  border: none;
}
