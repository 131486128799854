.container {
  display: inherit;
  position: relative;
}

.popup:global(.ui.popup) {
  width: auto;
  min-width: 240px;
  white-space: pre-line;
  inset: unset !important;
  background: #274059 !important;
  color: #fff !important;
  border: none !important;
  box-shadow: 0 0 48px #0c1f31 !important;
  text-align: left !important;
}

.popup:global(.ui.popup.bottom) {
  top: 100% !important;
}

.popup:global(.ui.popup.top) {
  bottom: 100% !important;
}

.popup:global(.ui.popup.center) {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.popup:global(.ui.popup.right) {
  right: 0 !important;
}

.popup:global(.ui.popup.left) {
  left: 0 !important;
}

.popup:global(.ui.popup.right) {
  right: 0 !important;
}

.popup:global(.ui.popup.left) {
  left: 0 !important;
}


.popup:global(.ui.popup):before {
  background: #274059 !important;
  box-shadow: none !important;
}

.title {

}

.content {
  padding: 0 !important;
}

.text {
  color: #9cb3c9 !important;
  margin-block-end: 0 !important;
  /* text-align: center; */
}

.action {
  color: #4799eb !important;
  cursor: pointer;
  text-align: center;
  display: block;
}