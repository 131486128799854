.event-section {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; /* border: 3px solid #1f364d; */
  border-bottom: 3px solid #1f364d;
  border-right: 3px solid #1f364d;
  border-left: 3px solid #1f364d;
  box-shadow: 0 0 28px rgb(0 0 0 / 7%);
  /* padding-bottom: 20px; */
  margin-top: -3px;
  max-width: 700px;
}

.post-section {
  padding-top: 15px;
  /* padding-bottom: 5px; */
}

.post-section:last-child {
  padding-bottom: 20px;
}

.feed-item-post {
  align-items: center !important;
  color: #9cb3c9 !important;
  display: flex !important;
  /* flex-wrap: wrap !important; */
  /* padding-right: 16px !important; */
  width: 100%;
  margin-top: 18px;
}

.img_picture {
  height: 32px !important;
  width: 32px !important;
  margin-left: -18px !important;
  position: absolute !important;
  /* transition: all 0.1s ease-in-out; */
}

.img_picture .image {
  border: 3px solid #1f364d !important;
  border-radius: 50% !important;
  height: 100% !important;
  width: 100% !important;
  object-fit: cover !important;
}

.img_picture:hover {
  /* box-shadow: 0 0 2px #fff; */
  border-radius: 50%;
  /* border: 2px solid white;*/
  /* outline: 2px solid white !important;*/
  box-shadow: 0 0 0 2px white;
  /* border: 1px solid #fff !important; */
}

.feed_item_control {
  padding: 0px 0px 0px 10px !important;
}

.sub_feed_item {
  /* align-items: center; */
  color: #63809c;
  display: flex;
  flex-wrap: wrap;
  font-size: 15px;
  line-height: 20px;
  margin-top: 1px;
  max-width: 100%;
}

.feed_item_title .feed_event_title {
  color: #fff !important;
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 19px;
  font-weight: 500;
  cursor: pointer;
}

.feed_hover_title:hover {
  background-color: #1f364d;
  border-radius: 2px;
  box-shadow: 4px 0 0 3px #1f364d, -4px 0 0 3px #1f364d;
  color: #fff;
  transition: 0.25s ease-in;
}
.feed_hover_title_cancel:hover {
  background-color: #1f364d;
  border-radius: 2px;
  box-shadow: 4px 0 0 3px #1f364d, -4px 0 0 3px #1f364d;
  color: #fff;
  transition: 0.25s ease-in;
  text-decoration: line-through;
}
.feed_hover:hover {
  background-color: #1f364d;
  border-radius: 2px;
  box-shadow: 4px 0 0 1px #1f364d, -4px 0 0 1px #1f364d;
  color: #fff;
  transition: 0.25s ease-in;
  cursor: pointer;
}


.feed_organizer_name {
  color: #63809c;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.sub_feed_item_dot {
  padding: 0px 6px !important;
  margin-bottom: 2px;
}

/* .feed_organizer_name:hover {
  color: #fff;
} */

.feed_atag {
  color: #4799eb;
  font-weight: 400 !important;
  width: fit-content;
}
.feed_atag_ {
  color: #4799eb;
  font-weight: 400 !important;
}

.feed_atag.white {
  color: #fff;
}

.feed_atag_dot_wrapper {
  display: inherit;
}

.add_to_cal_drop_wrapper {
  border: none !important;
}

.ui.floating.dropdown .menu.add_to_cal_drop {
  /* Change the width here for desktop */
  margin-top: 4px !important;
  width: 130px;
  border: 1px solid #1f364d;
  background-color: #0e2439;
}

.ui.dropdown .menu.add_to_cal_drop .item {
  padding: 12px 20px !important;
  font-size: 15px;
  line-height: 1.4285em;
  font-weight: 400;
  color: #fff !important;
}

.add_to_cal_drop > *:hover {
  background-color: #4799eb;
}


.feed-date {
  padding: 0px 0px 0px 30px;
  display: flex;
}
.feed-date .date {
  align-items: center;
  border: 3px solid #1f364d;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  height: 46px;
  justify-content: center;
  margin-top: 4px;
  text-align: center;
  width: 44px;
}
.feed-date:hover .date {
  border-color: #385c80;
}
.feed-date .date .month {
  color: #b3cce6;
  font-size: 11px;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
}
.feed-date .date .day {
  color: #4799eb;
  font-size: 15px;
  font-weight: 600;
  line-height: 1em;
  margin: 1px auto 0;
}

.feed_section_header {
  align-items: center;
  justify-items: start;
  display: flex;
  position: relative;
  height: 55px;
  padding: 0 8px;

  border-bottom: 3px dotted #182f45;

  border-top: 3px solid #1f364d;
  border-right: 3px solid #1f364d;
  border-left: 3px solid #1f364d;

  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

  box-shadow: 0 0 28px rgb(0 0 0 / 7%);
}

.nav_content {
  height: 49px;
  min-width: 0;
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;
}

.feed .infinite-scroll-component {
  overflow: visible !important;
}

.feed_section_nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.filter_button {
  background: #4799eb;
  width: 74px;
  height: 34px;
  color: #fff;
  padding: 8px 22px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.14285714rem;
}

.feed_section_header .feed_section_nav .menu-btn {
  /* display: inline !important; */
  margin-left: 0.25em;
}

.feed_section_header .feed_section_nav .button-wrapper {
  display: flex;
  flex-direction: row;
}

.filter_button:active {
  background: #197fe6;
}

.filter_button:hover {
  background: #5ea6ee;
  color: white;
}

.feed_section_sub_nav {
  border-left: 3px solid #1f364d;
  border-right: 3px solid #1f364d;
  border-bottom: 3px solid #1f364d;
  display: flex;
  height: 50px;
  padding: 4px 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.feed_section_sub_nav.no_border_bottom {
  border-bottom-color: transparent;
}

.nav_link {
  align-items: center;
  border-bottom: 3px solid transparent;
  color: #b6cce2;
  cursor: pointer;
  display: flex;
  flex: 0 0 auto;
  padding: 0 6px;
  font-weight: 400;
  font-size: 17px;
  margin-left: 4px;
  height: 52px;
}

.nav_link:hover {
  border-bottom: 3px solid #385c80;
  color: #fff;
  z-index: 1;
}
.sub_nav_link:hover {
  color: #b6cce2;
}
.sub_nav_link.active {
  color: #fff;
}

.nav_link.active {
  border-bottom: 3px solid #4799eb;
  color: #fff;
  fill: #fff;
  font-weight: 600;
  z-index: 1;
}

.sub_nav_link {
  align-items: center;
  text-align: center;
  color: #63809c;
  display: flex;
  font-weight: 600;
  padding: 0 6px;
  text-transform: uppercase;
}

.sub_nav_link_refresh {
  margin: 0 6px 0 auto;
  fill: #9cb3c9;
  width: 16px;
  height: 16px;
  align-self: center;

  display: none;
}

.sub_nav_link_refresh:hover {
  fill: #4799eb;
}


.featured-items + .post-section {
  padding-top: 2px;
}

.post-section.featured {
  margin: 4px 0;
  padding: 0;
  /* background-color: #274059; */
}

.post-section.featured:first-child {
  margin-top: 3px;
}

.post-section.featured:last-child {
  margin-bottom: 0;
}



.post-section.featured .feed-item-post {
  padding: 14.5px 0;
  margin: 0;
}



.post-section, .post-section.featured {
  padding-right: 10px;
}

.sub_feed_item_date {
  flex-basis: 100%;
}

@media only screen and (max-width: 375px) {
  .event-section {
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-bottom: 20px;
    margin-top: 0px;
    max-width: 700px;
  }

  .feed_section_header {
    align-items: center;
    justify-items: start;
    display: flex;
    position: relative;
    padding: 0 8px;

    border-bottom: none;

    border-top: none;
    border-right: none;
    border-left: none;

    border-top-right-radius: none;
    border-top-left-radius: none;

    box-shadow: none;
  }

  .nav_content {
    height: 59px;
    min-width: 0;
    display: flex;
    flex: 0 0 auto;
    white-space: nowrap;
  }


  

  .feed_section_sub_nav {
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-left: none;
    border-right: none;
    box-shadow: none;

    border-bottom: none;
    display: flex;
    height: 50px;
    padding: 4px 8px;
  }

  .img_picture {
    display: none;
  }

  .img_picture_mobile {
    /* position: absolute;
    right: 0; */
    margin-right: 10px;
    margin-left: 10px;
    height: 2em;
    width: 2em;
  }

  .img_picture_mobile .image {
    /* border: 2px solid #182f45; */
    height: 2em;
    width: 2em;
    border-radius: 50%;
    clip-path: circle(2em at center);
  }

  .feed-date {
    padding: 0px 0px 0px 0px;
  }
}

/* @media only screen and (min-width: 541px) {
  .img_picture_mobile {
    display: none;
  }
} */

@media only screen and (min-width: 769px) {
  .img_picture_mobile {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .event-section {
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-bottom: none;
    border-right: none;
    border-left: none;
    box-shadow: none;
    padding-bottom: 20px;
    margin-top: 0px;
    max-width: 700px;
  }

  .post-section, .post-section.featured {
    padding-left: 10px;
    padding-right: 0;
  }

  .feed-item-post {
    padding-bottom: 20px;
    border-bottom: 1px dotted #182f45;
    justify-content: space-between;
  }
  .feed_section_header {
    align-items: center;
    justify-items: start;
    display: flex;
    position: relative;
    height: 55px;
    padding: 0 0px;

    border-bottom: none;

    border-top: none;
    border-right: none;
    border-left: none;

    border-top-right-radius: none;
    border-top-left-radius: none;

    box-shadow: none;
  }

  .nav_content {
    height: 59px;
    min-width: 0;
    display: flex;
    flex: 0 0 auto;
    white-space: nowrap;
  }

  .feed_section_sub_nav {
    border-bottom-left-radius: none;
    border-bottom-right-radius: none;
    border-left: none;
    border-right: none;
    box-shadow: none;

    border-top: 1px dotted #182f45;
    border-bottom: 1px dotted #182f45;
    display: flex;
    height: 50px;
    padding: 4px 0px;
  }

  .img_picture {
    display: none;
  }

  .img_picture_mobile {
    /* position: absolute;
    right: 0; */
    margin-right: 10px;
    margin-left: 10px;
    height: 2em;
    width: 2em;
  }

  .img_picture_mobile .image {
    /* border: 2px solid #182f45; */
    height: 2em;
    width: 2em;
    border-radius: 50%;
    clip-path: circle(2em at center);
    object-fit: cover;
  }
  .img_picture_mobile .image:hover {
    /* border: 2px solid white; */
    /*outline: 2px solid white;*/
    box-shadow: 0 0 0 2px white;
    border-radius: 51%;
  }

  .feed-date {
    padding: 0px 0px 0px 0px;
  }

  .sub_feed_item_date {
    flex-basis: 100%;
  }

  /* Change width here for mobile */
  .add_to_cal_drop {
    /* width: auto; */
  }
}
