
.container {
  position: absolute;
  bottom: 0;
  left: 0;
  /* height: 100%; */
  width: 100%;
  padding-top: 12px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 3;
  overflow: hidden;
}


.modal {
  width: 100%;
  max-width: 780px;
  /* height: 100%; */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: 1px solid #1f364d;
  background-color: #0e2439;
  position: relative;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 48px;
  position: relative;
  padding: 0 24px;
  /* border-bottom: 1px solid #30404f */
}

.title {
  font-size: 17px;
  font-weight: 400;
  /* color: #b6cce2; */
}

.close {
  position: absolute;
  top: 16px;
  left: 24px;
  font-size: 22px;
  display: flex;
  font-weight: 400;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* width: 16px;
  height: 16px; */
}

.content {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height 100% - (48 + 66px) */
  /* height: calc(100% - calc(114px));     */
}

.gif {
  max-height: 44vh;
  /* width: 100%; */
}

.footer {
  border-top: 1px solid #30404f;
  height: 66px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 24px;
}

.clear {
  background: none;
  text-decoration: underline;
  border: none;
  padding: none;
  color: #ddd;
  cursor: pointer;
}

.clear:hover {
  color: #fff;
}



@media screen and (min-width: 768px) {
  .trigger span:not(.filterCount) {
    display: inline-block;
    margin-left: 3.7px;
  }

  .modal {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .header {
    height: 64px;
    padding: 0;
  }

  .container {
    padding: 40px;
  }

  .content {
    height: calc(100% - calc(130px));
  }

  .section {
    padding: 28px 0;
  }
}

  