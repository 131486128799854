.meetups {
  background-color: #0e2439;
  color: #9cb3c9;
  text-align: center;
}

.meetups {
  font-size: 16px;
}

.meetups .lpnav-wrapper {
  position: relative;
  /* background-color: #1f364d; */
  height: 56px;
}

.meetups .meetups-index .section-header {
  /* Old color */
  /* background-image: linear-gradient(to bottom,#1f364d,#0e2439); */
}

.meetups .meetups-index .section-header__title {
  margin: 0;
}

.meetups .meetups-index .section-header__description {
  margin-bottom: 0;
}

.meetups .meetups-index .section-header__action {
  line-height: normal;
}

.meetups .meetups-index__meetup-name {
  font-weight: 400;
}

@media (min-width: 600px) {
  .meetups .meetups-index .section-header {
    padding: 64px 16px 0;
  }
  .meetups .meetups-index .section-header__title {
    font-size: 40px;
  }
  .meetups .meetups-index .section-header__description {
    font-size: 22px;
  }
  .meetups .meetups-index .section-header__action--solo,
  .meetups .meetups-index .section-header__actions {
    margin: 36px auto 0;
  }
  .meetups .meetups-index .section-body {
    margin-bottom: 64px;
    margin-top: 64px;
  }
  .meetups .meetups-index .meetups-index__content {
    max-width: calc(100% - 32px);
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .section-header {
    padding: 48px 16px 0;
  }
  .meetups .meetups-index .section-header__title {
    font-size: 32px;
  }
  .meetups .meetups-index .section-header__description {
    font-size: 20px;
  }
  .meetups .meetups-index .section-header__actions {
    flex-direction: column;
  }
  .meetups .meetups-index .section-header__action--solo,
  .meetups .meetups-index .section-header__actions {
    margin: 24px auto 0;
  }
  .meetups .meetups-index .section-header__action--primary {
    margin-left: auto !important;
  }
  .meetups .meetups-index .section-header__action--secondary {
    margin-right: auto !important;
    margin-bottom: 14px !important;
  }
  .meetups .meetups-index .section-body {
    margin-bottom: 48px;
    margin-top: 48px;
  }
}
.meetups .meetups-index .meetups-index__content {
  align-items: flex-start !important;
  display: flex !important;
  justify-content: space-between !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 700px !important;
}
.meetups .meetups-index .meetups-index__list {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
}
.meetups .meetups-index .meetups-index__meetup {
  background-color: #1f364d;
  border-radius: 3px;
  box-shadow: 0 0 8px #0c1f31;
  /* position: relative; */
}
.meetups .meetups-index .meetups-index__meetup:not([disabled]) {
  cursor: pointer;
  transition: transform 160ms;
}
.meetups .meetups-index .meetups-index__meetup:not([disabled]):hover {
  transform: scale(1.05);
}
.meetups .meetups-index .meetups-index__meetup:hover:not([disabled]) {
  background-color: #2c4763;
}
.meetups .meetups-index .meetups-index__meetup:hover {
  z-index: 1;
}
.meetups .meetups-index .meetups-index__meetup:not(:first-child) {
  margin-top: 4px;
}

.meetups .meetups-index .meetups-index__meetup.blur:last-child {
  -webkit-mask: -webkit-linear-gradient(#10253a, transparent 100%, #10253a);
  -webkit-mask: linear-gradient(#10253a, transparent 100%, #10253a);
  height: 75px;
}

.meetups .meetups-index .meetups-index__meetup-link {
  align-items: center;
  border-radius: 3px;
  display: flex;
  text-align: left;
}

@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__meetup-link {
    padding: 36px 24px 36px 36px;
  }
  .meetups .meetups-index .meetups-index__date-icon {
    height: 60px;
    width: 58px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__content {
    max-width: calc(100% - 6px);
  }
  .meetups .meetups-index .meetups-index__meetup-link {
    padding: 20px;
  }
}
.meetups .meetups-index .meetups-index__edit-link {
  bottom: 0;
  border-radius: 3px;
  color: #4799eb;
  font-size: 14px;
  font-weight: 600;
  padding: 12px 16px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  z-index: 1;
}
.meetups .meetups-index .meetups-index__edit-link:hover {
  background-color: #325271;
  color: #fff;
}
.meetups .meetups-index .meetups-index__date-icon {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.meetups .meetups-index .meetups-index__month {
  color: #404040;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__month {
    font-size: 15px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__date-icon {
    height: 48px;
    width: 48px;
  }
  .meetups .meetups-index .meetups-index__month {
    font-size: 13px;
  }
}
.meetups .meetups-index .meetups-index__day {
  color: #4799eb;
  font-weight: 600;
  line-height: 1em;
  margin: 3px auto 0;
}
.meetups .meetups-index .meetups-index__meetup-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__day {
    font-size: 22px;
  }
  .meetups .meetups-index .meetups-index__meetup-text {
    margin-left: 28px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__day {
    font-size: 18px;
  }
  .meetups .meetups-index .meetups-index__meetup-text {
    margin-left: 20px;
  }
}
.meetups .meetups-index .meetups-index__meetup-name {
  color: #fff;
  margin-bottom: 0px;
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__meetup-name {
    font-size: 24px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__meetup-name {
    font-size: 20px;
  }
}
.meetups .meetups-index .meetups-index__meetup-footer {
  color: #809ab3;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.meetups .meetups-index .meetups-index__user-link:hover .user-link__name {
  color: #fff;
}
.meetups .meetups-index .meetups-index__user-link .user-link__avatar {
  flex: 0 0 auto;
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__meetup-footer {
    font-size: 16px;
    white-space: nowrap;
  }
  .meetups .meetups-index .meetups-index__user-link .user-link__avatar {
    height: 24px;
    width: 24px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__meetup-footer {
    font-size: 15px;
  }
  .meetups .meetups-index .meetups-index__user-link .user-link__avatar {
    display: none;
  }
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__user-link .user-link__name {
    font-size: 16px;
  }
}
@media (max-width: 600px) {
  .meetups .meetups-index .meetups-index__meetup.blur:last-child {
    -webkit-mask: -webkit-linear-gradient(#10253a, transparent 100%, #10253a);
    -webkit-mask: linear-gradient(#10253a, transparent 100%, #10253a);
    height: 60px;
  }
}
@media only screen and (max-width: 375px) {
  .meetups .meetups-index .meetups-index__meetup.blur:last-child {
    -webkit-mask: -webkit-linear-gradient(#10253a, transparent 100%, #10253a);
    -webkit-mask: linear-gradient(#10253a, transparent 100%, #10253a);
    height: 60px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__user-link .user-link__name {
    font-size: 15px;
    margin-left: 0;
  }
  .meetups .meetups-index .meetups-index__meetup.blur:last-child {
    -webkit-mask: -webkit-linear-gradient(#10253a, transparent 100%, #10253a);
    -webkit-mask: linear-gradient(#10253a, transparent 100%, #10253a);
    height: 55px;
  }
}
.meetups .meetups-index .meetups-index__user-link .user-link__badge {
  height: 14px;
  width: 14px;
  margin-left: 6px;
  transform: translateY(1px);
}
.meetups .meetups-index .meetups-index__separator {
  color: #63809c;
  margin: 0 6px;
}
.meetups .meetups-index .meetups-index__load-more-button {
  align-self: flex-start;
  margin-top: 32px;
}
@media (min-width: 600px) {
  .meetups .meetups-index .meetups-index__load-more-button {
    padding: 18px 24px;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__icon--spinning {
    animation: spin 1s infinite;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__label {
    font-size: 16px;
  }
}
@media (max-width: 599px) {
  .meetups .meetups-index .meetups-index__load-more-button {
    padding: 16px 22px;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__icon {
    height: 16px;
    width: 16px;
    margin-right: 10px;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__icon--spinning {
    animation: spin 1s infinite;
  }
  .meetups
    .meetups-index
    .meetups-index__load-more-button
    .load-more-button__label {
    font-size: 16px;
  }
}
.meetups .meetup .meetup__date-icon {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}
.meetups .meetup .date-icon__day {
  color: #e6375a;
  font-size: 14px;
}

.section-header {
  /* background-image: linear-gradient(to bottom, #1f364d, #0e2439); */
  display: flex;
  flex-direction: column;
  width: 100%;
}
.section-header .section-header__title {
  color: #fff;
  font-weight: 600;
  line-height: 1.25em;
}
.section-header .section-header__description {
  justify-content: center;
  line-height: 1.35em;
  margin-top: 14px;
}
.section-header .section-header__actions {
  align-items: center;
  display: flex;
  justify-content: center;
}
.section-header .section-header__action {
  align-self: flex-start;
  background-color: #4799eb;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  transition: all 180ms;
  margin: 0 auto;
  padding: 12px 16px;
}
.section-header .section-header__action:hover {
  /* background-color: #e6375a; */
}
.section-header .section-header__action:hover svg {
  animation: joinButtonArrowSvgGyration 0.9s infinite;
  /* fill: #e6375a; */
}
.series .series__button-spinner,
.series-subscription-form .series-subscription-form__button-spinner {
  animation: spin 1s infinite;
}
.section-header .section-header__action > div {
  align-items: center;
  display: flex;
  justify-content: center;
}
.section-header .section-header__action label {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  white-space: nowrap;
}
.section-header .section-header__action svg {
  height: 18px;
  width: 18px;
  fill: #4799eb;
  left: calc(100% + 10px);
  position: absolute;
  top: calc(50% - 9px);
  transition: all 180ms;
}
.section-header .section-header__action--secondary {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #1f364d;
  margin-right: 16px !important;
}
.section-header .section-header__action--secondary:hover {
  background-color: #4799eb;
  box-shadow: none;
}
.section-header .section-header__action--secondary:hover label {
  color: #fff;
}
.section-header .section-header__action--secondary + .section-header__action {
  margin-left: 0;
}
.section-header .section-header__action--secondary label {
  color: #b6cce2;
  font-weight: 400;
}

.user-avatar {
  border-radius: 9999px;
}
.user-avatar > * {
  border-radius: 9999px;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.user-avatar source {
  display: none;
}

.user-link__link,
.user-link__twitter-link {
  align-items: center;
  display: flex;
}

.section-header__action-label {
  cursor: pointer;
}

.user-link:hover .user-link__avatar {
  box-shadow: 0 0 0 2px #fff;
}
.user-link__avatar {
  height: 32px;
  width: 32px;
}
.user-link__name {
  margin-left: 8px;
}