.report-bug
{
    width: 100% !important;
}

.report-bug .form .field > label {
    font-size: 13px !important;
}

.report-bug .secondary.button {
    border-color: #274562;
}

.report-bug .ui.segment {
    background-color: #233b53;
}